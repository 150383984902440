import { featureAdapter } from '@store/portfolio/transactions-store/state';
import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function accountReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.LOAD_PURPOSE_CODES_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.LOAD_ACCOUNT_POSITIONS_REQUEST:
    case ActionTypes.LOAD_PAYMENT_ACCOUNTS_REQUEST:
    case ActionTypes.LOAD_MORE_ACCOUNT_TRANSACTIONS_REQUEST:
    case ActionTypes.LOAD_ACCOUNT_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_ACCOUNT_TRANSACTIONS_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_PAYMENT_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        paymentAccounts: action.payload,
      };
    }
    case ActionTypes.LOAD_PURPOSE_CODES_SUCCESS: {
      return {
        ...state,
        error: null,
        purposeCodes: action.payload,
      };
    }

    case ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        transactionsExport: null,
      };
    }

    case ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        transactionsExport: action.payload,
      };
    }

    case ActionTypes.LOAD_MORE_ACCOUNT_TRANSACTIONS_SUCCESS: {
      const existingIds = Object.values(state.entities).map(
        (entity: any) => entity.externalId
      );
      const entities = action.payload.filter(
        (entity: any) => !existingIds.includes(entity.externalId)
      );
      return featureAdapter.addMany(entities, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_ACCOUNT_POSITIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        positions: action.payload,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.SET_SELECTED_ACCOUNT: {
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedAccount: action.payload,
      };
    }
    case ActionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...action.payload },
        appliedFilters: action.payload.totalAppliedFilters,
      };
    }
    case ActionTypes.CLEAR_FILTER: {
      return {
        ...state,
        filter: {},
        appliedFilters: 0,
      };
    }

    case ActionTypes.CLEAR_PRINT_ACTION: {
      return {
        ...state,
        documentId: '',
      };
    }
    case ActionTypes.TRANSACTION_PRINT_STATUS_SUCCESS: {
      return {
        ...state,
        documentId: action.payload.documentId,
        documentState: action.payload.documentState,
      };
    }
    default: {
      return state;
    }
  }
}
