import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DocumentActions, DocumentSelectors } from '@store/document-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmp-unread-counter',
  templateUrl: './wmp-unread-counter.component.html',
  styleUrls: ['./wmp-unread-counter.component.scss'],
})
export class WmpUnreadCounterComponent implements OnInit, OnDestroy {
  public value = 0;
  public tooltipTextCode = '';
  private unreadSubscription?: Subscription;
  private clientSubscription?: Subscription;

  constructor(
    private store$: Store,
    private translate: TranslateService,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.unreadSubscription = this.store$
      .select(DocumentSelectors.selectUnreadItems)
      .subscribe((unread) => {
        this.value = unread;
        this.tooltipTextCode = this.getTooltipTextCode();
      });
    this.clientSubscription = this.navigationService.clients$.subscribe(
      (clients) => {
        if (clients) {
          this.store$.dispatch(new DocumentActions.RequestUnreadCounter());
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.unreadSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
  }

  getTooltipTextCode(): string {
    return this.value > 1
      ? this.translate.instant('documents.unreadCounterTooltip.plural')
      : this.translate.instant('documents.unreadCounterTooltip.singular');
  }
}
