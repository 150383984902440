import { Injectable } from '@angular/core';
import { AccountService } from '@core/services/account/account.service';
import { TransactionsActionParams } from '@models/account.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccountActions } from '@store/account-store/index';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AccountActionTypes from './actionTypes';

@Injectable()
export class AccountEffects {
  exportAccountTransactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.EXPORT_TRANSACTIONS_AS_CSV_REQUEST),
      mergeMap((action: AccountActions.LoadAccountTransactionsAction) =>
        this.accountService.exportAccountTransactions(action.payload).pipe(
          map(
            (response) =>
              new AccountActions.ExportAccountTransactionsAsCsvSuccessAction(
                response
              )
          ),
          catchError((error) => {
            this.store$.dispatch(
              new AccountActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadMoreAccountTransactions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.LOAD_MORE_ACCOUNT_TRANSACTIONS_REQUEST),
      mergeMap(
        (action: AccountActions.LoadMoreAccountTransactionsRequestAction) =>
          this.accountService.getAccountTransactions(action.payload).pipe(
            map(
              (result) =>
                new AccountActions.LoadMoreAccountTransactionsSuccessAction(
                  result
                )
            ),
            catchError((error) => {
              this.store$.dispatch(
                new AccountActions.LoadFailureAction({
                  error: error.message ? error.message : error,
                })
              );
              return throwError(error);
            })
          )
      )
    );
  });

  loadPositionsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.LOAD_ACCOUNT_POSITIONS_REQUEST),
      mergeMap((action: AccountActions.LoadAccountPositionsRequestAction) =>
        this.accountService.getPositions(action.payload).pipe(
          map((elements) => {
            const sortedPositions = elements.positions.sort(
              (current: any, next: any) =>
                current.assetClassId < next.assetClassId ? -1 : 1
            );
            return new AccountActions.LoadAccountPositionsSuccessAction(
              sortedPositions
            );
          }),
          catchError((error) => {
            this.store$.dispatch(
              new AccountActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  loadPaymentAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.LOAD_PAYMENT_ACCOUNTS_REQUEST),
      mergeMap(() =>
        this.accountService.getPaymentAccounts().pipe(
          map(
            (elements) =>
              new AccountActions.LoadPaymentAccountsSuccessAction(elements)
          ),
          catchError((error) => {
            this.store$.dispatch(
              new AccountActions.LoadFailureAction({
                error: error.message ? error.message : error,
              })
            );
            return throwError(error);
          })
        )
      )
    );
  });

  getDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.TRANSACTION_PRINT_REQUEST),
      mergeMap((action: AccountActions.TransactionPrintRequestAction) => {
        return this.accountService
          .printTransactionDocument(action.payload)
          .pipe(
            map((response: TransactionsActionParams) => {
              if (response.documentId && action.payload.cashAccountId) {
                const serviceResult = this.accountService.getPrintedPdfState(
                  response.documentId,
                  action.payload.cashAccountId
                );
                this.accountService.pollPrintStatusResponse(serviceResult);
                return new AccountActions.TransactionPrintSuccessAction(
                  response
                );
              } else {
                return new AccountActions.TransactionPrintSuccessAction(
                  response
                );
              }
            }),
            catchError((error) => {
              this.store$.dispatch(
                new AccountActions.TransactionPrintFailureAction({
                  error: error.message ? error.message : error,
                })
              );
              return throwError(error);
            })
          );
      })
    );
  });

  getPendingDocumentId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActionTypes.TRANSACTION_PRINT_PENDING_REQUEST),
      mergeMap(
        (action: AccountActions.TransactionPrintPendingRequestAction) => {
          return this.accountService
            .printPendingPaymentDocument(action.payload)
            .pipe(
              map((response: TransactionsActionParams) => {
                if (response.documentId && action.payload.cashAccountId) {
                  const serviceResult = this.accountService.getPrintedPdfState(
                    response.documentId,
                    action.payload.cashAccountId
                  );
                  this.accountService.pollPrintStatusResponse(serviceResult);
                  return new AccountActions.TransactionPrintPendingSuccessAction(
                    response
                  );
                } else {
                  return new AccountActions.TransactionPrintPendingSuccessAction(
                    response
                  );
                }
              }),
              catchError((error) => {
                this.store$.dispatch(
                  new AccountActions.TransactionPrintPendingFailureAction({
                    error: error.message ? error.message : error,
                  })
                );
                return throwError(error);
              })
            );
        }
      )
    );
  });

  constructor(
    private store$: Store,
    private actions$: Actions,
    private accountService: AccountService
  ) {}
}
