import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  PATH_PAYMENT_NEW,
  PATH_PAYMENT_REVIEW,
  PATH_PAYMENTS,
} from '@core/constants/path';
import { AuthService } from '@core/services/auth.service';
import { LanguageService } from '@core/services/language.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { SessionStorageService } from '@core/services/session-storage-util.service';
import { TwoFactorAuthService } from '@core/services/twoFactorAuth/two-factor-auth.service';
import { Environment } from '@models/auth-session.model';
import { STATE_TYPE } from '@models/authentication/authentication.model';
import { IdleService } from '@modules/idle/idle.services';
import { Store } from '@ngrx/store';
import { AuthActions, AuthSelectors, AuthStore } from '@store/auth-store';
import { NavigationActions } from '@store/navigation/navigation-store';
import { RelationshipinfoAction } from '@store/navigation/relationshipinfo-store';
import { PaymentActions } from '@store/payment-store';
import { Observable, Subscription } from 'rxjs';
import { SecureLayoutComponent } from './layout/secure/layout.component';

@Component({
  selector: 'wmp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  getState: Observable<any> | undefined;
  isLoggedIn = false;
  subscription: Subscription | undefined;
  previousUrl = '';

  private clientSubscription?: Subscription;

  constructor(
    private router: Router,
    private store$: Store<AuthStore.State>,
    private languageService: LanguageService,
    private twoFactorAuthentication: TwoFactorAuthService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private sessionStorageService: SessionStorageService,
    private idleService: IdleService,
    private authService: AuthService,
    private navigationService: NavigationService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  onDestroy(): void {
    const authSession = sessionStorage.getItem('auth_session');
    if (authSession) {
      if (this.isLoggedIn) {
        this.store$.dispatch(new AuthActions.CancelAuthSession(authSession));
      } else {
        this.store$.dispatch(
          new AuthActions.CancelLoginAuthSession(authSession)
        );
      }
    }
  }

  ngOnInit(): void {
    this.languageService.init();
    this.resetModelWhenRouteChange();
    this.checkRmpCredentials();
    this.getState = this.store$.select(AuthSelectors.selectAuthState);
    this.getState.subscribe((state) => {
      this.isLoggedIn = state.isLoggedIn;
    });

    this.router.events.subscribe(() => {
      const rootComponent = this.activatedRoute.snapshot.firstChild?.component;
      if (rootComponent) {
        rootComponent === SecureLayoutComponent
          ? this.renderer.addClass(document.body, 'secure')
          : this.renderer.removeClass(document.body, 'secure');
      }
    });
    this.idleService.init();
  }

  ngOnDestroy(): void {
    this.clientSubscription?.unsubscribe();
  }

  private resetModelWhenRouteChange(): void {
    this.subscription?.unsubscribe();
    this.subscription = this.router.events.subscribe((routeChange) => {
      const authSession = sessionStorage.getItem('auth_session');
      if (routeChange instanceof NavigationEnd) {
        if (
          routeChange.id === 1 &&
          routeChange.url === routeChange.urlAfterRedirects
        ) {
          this.resetStoreData();
          this.onRefreshPage(authSession, routeChange);
          this.setSelectedLanguage();
          sessionStorage.removeItem('isRmpLoginSuccessful');
        } else if (
          this.previousUrl.includes(PATH_PAYMENTS) &&
          routeChange.url !== PATH_PAYMENT_NEW &&
          routeChange.url !== PATH_PAYMENT_REVIEW
        ) {
          this.resetStoreData();
        }
      } else if (routeChange instanceof NavigationStart) {
        if (routeChange.navigationTrigger === 'popstate' && authSession) {
          this.resetStoreData();
          this.cancelSecSignAuthenticationOnNavigationBack(
            routeChange,
            authSession
          );
        }
        this.previousUrl = routeChange.url;
      }
    });
  }

  private cancelSecSignAuthenticationOnNavigationBack(
    routeChange: NavigationStart,
    authSession: string
  ) {
    this.twoFactorAuthentication.finishSecSignAuthentication(
      null,
      STATE_TYPE.CANCELED
    );
    this.cancelSession(routeChange, authSession);
  }

  private onRefreshPage(
    authSession: string | null,
    routeChange: NavigationEnd
  ) {
    if (authSession) {
      this.cancelSession(routeChange, authSession);
    }
  }

  private cancelSession(
    routeChange: NavigationStart | NavigationEnd,
    authSession: string
  ) {
    if (routeChange.url.includes('/auth/login')) {
      this.cancelLoginAuthSession(authSession);
    } else {
      this.cancelAuthSession(authSession);
    }
  }

  private cancelAuthSession(authSession: string): void {
    this.store$.dispatch(new AuthActions.CancelAuthSession(authSession));
    sessionStorage.removeItem('auth_session');
  }

  private cancelLoginAuthSession(authSession: string): void {
    this.store$.dispatch(new AuthActions.CancelLoginAuthSession(authSession));
    sessionStorage.removeItem('auth_session');
  }

  private resetStoreData() {
    this.store$.dispatch(new PaymentActions.SetCreatedPayment(undefined));
  }

  private setSelectedLanguage() {
    if (this.authService.getUserLoggedIn()) {
      this.store$.dispatch(
        new NavigationActions.GetSelectedLanguageRequestAction()
      );
      this.languageService.setSelectedLanguage();
    }
  }

  private checkRmpCredentials() {
    if (sessionStorage.getItem('environment') === Environment.RMP) {
      this.clientSubscription = this.navigationService.clients$.subscribe(
        (clients) => {
          if (clients) {
            if (this.sessionStorageService.isAClientSelected()) {
              this.store$.dispatch(new RelationshipinfoAction.ResetData());
              this.router.navigate(['/portfolio/summary']);
            }
          }
        }
      );
    }
  }
}
