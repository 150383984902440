import {
  AccountPositionsModel,
  AccountTransactionFilterModel,
  PaymentAccountsModel,
  ProductModel,
  PurposeCodesModel,
  TransactionsPrintRequestParams,
  TransactionsActionParams,
} from '@models/account.model';
import { PortfolioTransactionModel } from '@models/transactions.model';
import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadAccountTransactionsAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOUNT_TRANSACTIONS_REQUEST;
  constructor(public payload: any) {}
}

export class LoadAccountTransactionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOUNT_TRANSACTIONS_SUCCESS;
  constructor(public payload: PortfolioTransactionModel[]) {}
}

export class LoadMoreAccountTransactionsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_ACCOUNT_TRANSACTIONS_REQUEST;
  constructor(public payload: any) {}
}

export class LoadMoreAccountTransactionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MORE_ACCOUNT_TRANSACTIONS_SUCCESS;
  constructor(public payload: any) {}
}

export class SetItemsPerPage implements Action {
  readonly type = ActionTypes.SET_ITEMS_PER_PAGE;
  constructor(public payload: number) {}
}

export class SetSelectedAccount implements Action {
  readonly type = ActionTypes.SET_SELECTED_ACCOUNT;
  constructor(public payload: ProductModel) {}
}

export class LoadAccountPositionsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOUNT_POSITIONS_REQUEST;
  constructor(public payload: any) {}
}

export class LoadAccountPositionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ACCOUNT_POSITIONS_SUCCESS;
  constructor(public payload: AccountPositionsModel[]) {}
}

export class LoadPaymentAccountsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PAYMENT_ACCOUNTS_REQUEST;
}

export class LoadPaymentAccountsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PAYMENT_ACCOUNTS_SUCCESS;
  constructor(public payload: PaymentAccountsModel[]) {}
}
export class SetFilter implements Action {
  readonly type = ActionTypes.SET_FILTER;
  constructor(public payload: AccountTransactionFilterModel) {}
}

export class LoadPurposeCodesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PURPOSE_CODES_REQUEST;
  constructor(public payload: string) {}
}

export class ClearFilterAction implements Action {
  readonly type = ActionTypes.CLEAR_FILTER;
}

export class ClearPrintAction implements Action {
  readonly type = ActionTypes.CLEAR_PRINT_ACTION;
}

export class LoadPurposeCodesRequestSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PURPOSE_CODES_SUCCESS;
  constructor(public payload: PurposeCodesModel[]) {}
}
export class TransactionPrintRequestAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_REQUEST;
  constructor(public payload: TransactionsPrintRequestParams) {}
}

export class TransactionPrintSuccessAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintFailureAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class TransactionPrintPendingRequestAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_REQUEST;
  constructor(public payload: TransactionsPrintRequestParams) {}
}

export class TransactionPrintPendingSuccessAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintPendingFailureAction implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_PENDING_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class TransactionPrintStatus implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS;
}

export class TransactionPrintStatusSuccess implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS_SUCCESS;
  constructor(public payload: TransactionsActionParams) {}
}

export class TransactionPrintStatusFailure implements Action {
  readonly type = ActionTypes.TRANSACTION_PRINT_STATUS_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class ExportAccountTransactionsAsCsvRequestAction implements Action {
  readonly type = ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_REQUEST;
  constructor(public payload: any) {}
}

export class ExportAccountTransactionsAsCsvSuccessAction implements Action {
  readonly type = ActionTypes.EXPORT_TRANSACTIONS_AS_CSV_SUCCESS;
  constructor(public payload: PortfolioTransactionModel[]) {}
}
