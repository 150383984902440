import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const FEATURE_KEY = 'navigation';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export interface State extends EntityState<any> {
  isLoading: boolean;
  error?: any;
  language: string;
  selectedLanguage?: string;
  isDemoUser: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  isDemoUser: false,
  error: null,
  language: '',
});
