import { Action } from '@ngrx/store';
import * as ActionTypes from './actionTypes';

export class SetLanguageRequestAction implements Action {
  readonly type = ActionTypes.SET_LANGUAGE_REQUEST;
  constructor(public payload: string) {}
}

export class SetLanguageSuccessAction implements Action {
  readonly type = ActionTypes.SET_LANGUAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSelectedLanguageRequestAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_LANGUAGE_REQUEST;
}

export class GetSelectedLanguageSuccessAction implements Action {
  readonly type = ActionTypes.GET_SELECTED_LANGUAGE_SUCCESS;
  constructor(public payload: string) {}
}

export class IsDemoUserRequestAction implements Action {
  readonly type = ActionTypes.IS_DEMO_USER_REQUEST;
}

export class IsDemoUserSuccessAction implements Action {
  readonly type = ActionTypes.IS_DEMO_USER_SUCCESS;
  constructor(public payload: boolean) {}
}

export class FailureAction implements Action {
  readonly type = ActionTypes.SET_FAILURE;
  constructor(public payload: { error: string }) {}
}
