<ng-container *ngIf="getIsAnnouncementView()">
  <ng-container *ngIf="announcements$ | async as announcements">
    <ng-container *ngIf="announcements.messageEn || announcements.messageDe">
      <div
        *ngIf="getCurrentLang() === 'en'; else showGermanMessage"
        class="alert announcement"
        role="alert"
      >
        {{ announcements.messageEn }}
      </div>
      <ng-template #showGermanMessage>
        <div class="alert announcement" role="alert">
          {{ announcements.messageDe }}
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
