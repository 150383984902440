import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { AnnouncementModel } from '@models/announcement.model';

@Component({
  selector: 'wmp-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
  private isAnnouncementView = false;
  private subscriptions$: Subscription;
  public announcements$?: Observable<AnnouncementModel>;

  constructor(
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private router: Router
  ) {
    this.subscriptions$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAnnouncementView = ['/portfolio/summary', '/portfolio'].includes(
          event.url
        );
      }
    });
  }

  public ngOnInit(): void {
    this.fetchAnnouncements();
  }

  fetchAnnouncements(): void {
    this.announcements$ = this.navigationService.getAnnouncements();
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public getIsAnnouncementView(): boolean {
    return this.isAnnouncementView;
  }

  public getCurrentLang() {
    return this.translateService.currentLang;
  }
}
