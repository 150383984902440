import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedCurrency',
  pure: false,
})
export class LocalizedCurrencyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    const currency: string = args?.currency ?? 'EUR';
    const currencyDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name' =
      args?.currencyDisplay ?? 'code';
    const minimumFractionDigits: number = args?.minimumFractionDigits ?? 2;
    const maximumFractionDigits: number = args?.maximumFractionDigits ?? 2;
    const excludeCode: string = args?.excludeCode ?? false;

    const result = Number(value).toLocaleString(
      this.translateService.currentLang,
      {
        style: 'currency',
        minimumFractionDigits,
        maximumFractionDigits,
        currency,
        currencyDisplay,
      }
    );

    return excludeCode ? result.replace(currency, '').trim() : result;
  }
}
