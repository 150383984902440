import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FEATURE_KEY, featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;
export const getLanguage = (state: State): any => state.language;

export const fetchSelectedLanguage = (state: State): any =>
  state.selectedLanguage;
export const getIsLoading = (state: State): boolean => state.isLoading;
export const getIsDemoUser = (state: State): boolean => state.isDemoUser;

export const selectState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FEATURE_KEY);
export const { selectAll, selectTotal } =
  featureAdapter.getSelectors(selectState);
export const selectError: MemoizedSelector<object, any> = createSelector(
  selectState,
  getError
);
export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
export const selectLanguage: MemoizedSelector<object, string> = createSelector(
  selectState,
  getLanguage
);
export const getSelectedLanguage: MemoizedSelector<object, string> =
  createSelector(selectState, fetchSelectedLanguage);
export const selectIsDemoUser: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsDemoUser);
