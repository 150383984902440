import * as ActionTypes from './actionTypes';
import { initialState, State } from './state';

export function navigationReducer(
  state: State = initialState,
  action: any = {}
): State {
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE_REQUEST:
    case ActionTypes.GET_SELECTED_LANGUAGE_REQUEST:
    case ActionTypes.IS_DEMO_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        language: action.payload,
      };
    }

    case ActionTypes.IS_DEMO_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        isDemoUser: action.payload,
      };
    }

    case ActionTypes.GET_SELECTED_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        selectedLanguage: action.payload.language,
      };
    }

    case ActionTypes.SET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
